import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src='/img/logo.png' className="App-logo" alt="logo" />
          <p>
            <a href="tel:+56998229224"><span><i className="fas fa-mobile-alt"></i></span> +569 98229224</a>
          </p>
          <p>
            <a href="mailto:contacto@atconsoluciones.cl"><span><i className="far fa-envelope"></i></span> contacto@atconsoluciones.cl</a>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
